import firebase from "firebase/app";
import "firebase/auth";

// TODO when deploying do this https://stackoverflow.com/a/56818296/1846993
const firebaseConfig = {
	apiKey: process.env.FIREBASE_API_KEY,
	authDomain: process.env.FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.FIREBASE_DATABASE_URL,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.FIREBASE_APP_ID,
	measurementId: process.env.FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const onAuthStateChanged = (handleAuthChange) => {
	firebase.auth().onAuthStateChanged(handleAuthChange);
};

export const login = (email, password) => {
	return new Promise((resolve, reject) => {
		return firebase.auth().signInWithEmailAndPassword(email, password)
			.then((user) => resolve(user))
			.catch((err) => {
				switch (err.code) {
				case "auth/user-not-found":
					return reject('דואר אלקטרוני לא נמצא');
				case "auth/user-disabled":
					return reject('אימייל מושעה');
				case "auth/network-request-failed":
					return reject('שגיאה בחיבור לרשת');
				case "auth/wrong-password":
					return reject('סיסמא אינה נכונה');
				case "auth/too-many-requests":
					return reject('מספר נסיונות כניסה מעל למצופה, אנא המתן 2 דקות');
				default:
					return reject(`שגיאה בכניסה (${err.code})`);
				}
			});
	});
};

export const register = (email, password) => {
	return new Promise((resolve, reject) => {
		return firebase.auth().createUserWithEmailAndPassword(email, password)
			.then((data) => resolve(data.user))
			.catch((err) => {
				switch (err.code) {
				case "auth/email-already-in-use":
					return reject('דואר אלקטרוני כבר בשימוש');
				case "auth/invalid-email":
					return reject('דואר אלקטרוני לא חוקי');
				case "auth/weak-password":
					return reject('הסיסמא אינה חזקה מספיק');
				default:
					return reject(`שגיאה ברישום (${err.code})`);
				}
			});
	});
};

export const logout = () => {
	return firebase.auth().signOut();
};

export const loginToFirebaseWithToken = token => firebase.auth().signInWithCustomToken(token);

export const getToken = async () => firebase.auth().currentUser?.getIdToken();

export const sendPasswordResetEmail = email => new Promise((resolve, reject) => {
	return firebase.auth().sendPasswordResetEmail(email)
		.then(() => resolve())
		.catch((err) => {
			// eslint-disable-next-line no-console
			console.log(err);
			return reject('לא ניתן לאפס סיסמא באמצעות אימייל זה');
		});
});
