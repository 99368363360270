import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import connect from "react-redux/es/connect/connect";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { authenticate, handleLogout, handleResize } from "../actions";
import { setIsLoggedIn } from "../actions/generalActions";
import { onAuthStateChanged } from "../helpers/firebaseApi";
import history from "../helpers/historyService";
import { getUser } from "../selectors";
import "../styles/App.scss";
import DashboardProtectedRoute from "./DashboardProtectedRoute";
import FAQContainer from "./faq/FAQContainer";
import FullScreenLoading from "./FullScreenLoading";
import PaymentSuccessRedirectContainer from "./go-premium/PaymentSuccessRedirectContainer";
import Home from "./home/Home";
import ModalRoot from "./modals/ModalRoot";
import PublicRoute from "./PublicRoute";
import WhatsAppBadge from "./whatsapp/WhatsAppBadge";

const DashboardContainer = React.lazy(() => import("../components/dashboard/DashboardContainer"));

const AppContainer = ({ handleResize, user, authenticate, logout, setIsLoggedIn }) => {
	const [isLoading, setIsLoading] = useState(true);
    
	const navigateHome = () => {
		history.push('/home');
	};
    
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		onAuthStateChanged(fbUser => {
			if (fbUser && !user) {
				authenticate(() => {
					setIsLoggedIn(true);
					setIsLoading(false);
				}, () => {
					setIsLoggedIn(false);
					setIsLoading(false);
					logout();
					navigateHome();
				});
			} else
				setIsLoading(false);
		});
        
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
    
	if (isLoading)
		return <FullScreenLoading />;
    
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Router history={history}>
				<Switch>
					<PublicRoute path="/home" component={Home} />
					<DashboardProtectedRoute path="/dashboard" component={DashboardContainer} />
					<Route path="/faq/:name?" component={FAQContainer} />
					<Route path="/paymentSuccess" component={PaymentSuccessRedirectContainer} />
					<Redirect from="/" to="/dashboard" />
				</Switch>
				<ModalRoot />
				<WhatsAppBadge />
			</Router>
		</MuiPickersUtilsProvider>
	);
};

AppContainer.propTypes = {
	handleResize: PropTypes.func,
};

const mapStateToProps = state => ({
	user: getUser(state)
});

const mapDispatchToProps = {
	handleResize,
	authenticate,
	logout: handleLogout,
	setIsLoggedIn
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
