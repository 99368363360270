import React from 'react';
import Fade from "../Fade";
import "./styles/VideoContainer.scss";

const VideoContainer = ({ video }) => {
	return (
		<div styleName="video-container">
			<Fade isVisible key={video.title}>
				<video src={video.url} loop muted controls height={400} autoPlay/>
			</Fade>
		</div>
	);
};

export default VideoContainer;
