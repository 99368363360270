import * as types from "../actions/actionTypes";

export function UserReducer(state = null, action = null) {
	switch (action.type) {
	case types.UPDATE_ACTIVE_USER_SUCCESS:
	case types.LOGIN_SUCCESS:
	case types.REGISTER_SUCCESS:
	case types.ME_FROM_TOKEN_SUCCESS: {
		return {
			...action.payload
		};
	}
	case types.REGISTER_FAILURE:
	case types.CLEAR_USER: {
		return null; // Empty User
	}
	case types.UPDATE_COMPANY_SUCCESS: {
		return {
			...state,
			company: action.payload,
		};
	}
	default:
		return state;
	}
}
