import { IconButton, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from "@material-ui/icons/Close";
import React from 'react';
import "./styles/LoginRegister.scss";

const MbDialogTitle = ({ children, onClose }) => {
	return (
		<MuiDialogTitle disableTypography styleName="dialog-title">
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton onClick={onClose}><CloseIcon/></IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

export default MbDialogTitle;
