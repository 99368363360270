import { EModalType } from "../components/modals/EModalType";
import { ESplashScreenType, hasSeenSplashScreen, setSeenSplashScreen } from "../helpers/SplashScreenHelper";
import * as actions from "./actionTypes";
import { showModal } from "./index";

export const fetchMetaDataSuccess = (payload) => {
	return {
		type: actions.FETCH_META_DATA_SUCCESS,
		payload
	};
};

export const setIsLoggedIn = (payload) => {
	return {
		type: actions.IS_LOGGED_IN,
		payload
	};
};

export const fetchMetaData = () => ({
	type: actions.API,
	payload: {
		url: "/general/meta",
		method: "get",
		success: fetchMetaDataSuccess,
	},
});

export const displaySplashScreen = (hasPremium, isCreditCardNeeded) => dispatch => {
	if (hasPremium && isCreditCardNeeded) {
		dispatch(showModal(EModalType.CREDIT_CARD_NEEDED));
		return;
	}

	let splashScreenType = ESplashScreenType.REPORT_APPROVAL;
	let hasSeen = hasSeenSplashScreen(splashScreenType);

	if (hasSeen || !hasPremium)
		return false;

	dispatch(showModal(EModalType.REPORT_APPROVAL));
	setSeenSplashScreen(splashScreenType);
};

export const loadDashboardData = (hasPremium, isCreditCardNeeded) => dispatch => {
	dispatch(displaySplashScreen(hasPremium, isCreditCardNeeded));
};

