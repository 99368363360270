import React, { useState } from 'react';
import { GAAction, sendGaEvent } from "../../helpers/GAService";
import SectionTitle from "./SectionTitle";
import "./styles/Videos.scss";
import VideoContainer from "./VideoContainer";
import VideosSideBar from "./VideosSideBar";

// urls of videos are relative to the dist directory
const videos = [
	{
		url: "../../videos/ShiftStatus.mp4",
		title: "מצב משמרת"
	},
	{
		url: "../../videos/Reports.mp4",
		title: "ניהול משמרות"
	},
	{
		url: "../../videos/Excel.mp4",
		title: "דוח אקסל חודשי"
	},
	{
		url: "../../videos/Location.mp4",
		title: "מיקום העובד"
	},
];

const Videos = () => {
	const [video, setVideo] = useState(videos[0]);

	const onSelect = video => {
		setVideo(video);
		sendGaEvent({
			action: GAAction.PROMOTION_VIDEO_SELECTED,
			category: video.title
		});
	};

	return (
		<div styleName="container">
			<SectionTitle title="איך זה עובד?"/>
			<div styleName="content">
				<VideosSideBar onSelect={onSelect} videos={videos} selectedVideo={video}/>
				<VideoContainer video={video}/>
			</div>
		</div>
	);
};

export default Videos;
