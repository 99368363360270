const { REGULAR } = require("../models/ETaskType");
const EShiftStatus = require("../public/helpers/EShiftStatus"); // https://github.com/exceljs/exceljs/issues/1551
const isValidEmail = email => {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
};

const FORZEN_ROWS_NUM = 3;
const EXCEL_SHEET_NAME_LIMIT = 31;

const RowBorderStyle = {
	top: { style: "hair" },
	left: { style: "medium" },
	bottom: { style: "hair" },
	right: { style: "medium" }
};

const HeaderBorderStyle = {
	top: { style: "medium" },
	left: { style: "medium" },
	bottom: { style: "medium" },
	right: { style: "medium" }
};

/*!
 * Determines if `val` is an object that has no own keys
 */
const isEmptyObject = val => val != null &&
	typeof val === 'object' &&
	Object.keys(val).length === 0;

const getFirstLocation = shift => {
	if (!shift)
		return null;

	if (shift.locations && shift.locations.length > 0)
		return shift.locations[0];

	return shift.location;
};

const parse2DigitsFloat = floatNum => parseFloat(floatNum.toFixed(2));

// Shift which is not related to task or related to task of type REFULAR
const isRegularShift = task => {
	return !task || task && task.type === REGULAR;
};

const isInnovativeTaskRelatedShift = task => {
	return task && task.isInnovative;
};

const isEmail = email => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

const isWorksheetNameExists = (workbook, title) => workbook._worksheets.find(w => w && w.name === title);

const handleTitle = (title, workbook) => {
	title = title.replace(/'/g, ""); // Omit name with single quote "'" (bug fix)
	title = title.substring(0, EXCEL_SHEET_NAME_LIMIT);
	while (isWorksheetNameExists(workbook, title)) // Preventing the same name employee (bug fix)
		title += '1';
	return title;
};

const addWorksheet = (workbook, title, color, header, frozenRows = 1) => {
	title = handleTitle(title, workbook);

	return workbook.addWorksheet(title, {
		properties: {
			tabColor: { argb: color }
		},
		views: [ {
			state: 'frozen',
			xSplit: 1,
			ySplit: frozenRows,
			rightToLeft: true
		} ],
		headerFooter: {
			oddHeader: header // BUG - https://github.com/exceljs/exceljs/issues/1325
		},
		pageSetup: {
			paperSize: 9, // A4
			orientation: 'landscape',
			fitToPage: true
		}
	});
};

const setSummaryHeaderColor = sheet => {
	setHeaderColor(sheet);
};

const setRowBold = row => {
	row.font = { bold: true };
};

const markBorders = (sheet, row, borderStyle) => {
	sheet.columns.map(column => {
		row.getCell(column.key).border = borderStyle;
	});
};

const setHeaderColor = (sheet, headerRowStart = 1) => {
	let headerRow = sheet.getRow(headerRowStart);

	setRowBold(headerRow);
	markBorders(sheet, headerRow, HeaderBorderStyle);
};

const isShiftPending = shift => {
	return shift.status === EShiftStatus.PENDING_CREATE || shift.status === EShiftStatus.PENDING_UPDATE;
};

const mergeByProperty = (target, source, prop) => {
	source.forEach(sourceElement => {
		let targetElement = target.find(targetElement => {
			return sourceElement[prop] === targetElement[prop];
		});
		targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
	});
};

module.exports = {
	isValidEmail,
	isEmptyObject,
	getFirstLocation,
	parse2DigitsFloat,
	isRegularShift,
	isInnovativeTaskRelatedShift,
	isEmail,
	addWorksheet,
	FORZEN_ROWS_NUM,
	setSummaryHeaderColor,
	setRowBold,
	markBorders,
	setHeaderColor,
	RowBorderStyle,
	isShiftPending,
	mergeByProperty
};
