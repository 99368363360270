import * as React from "react";

function ZoomSvg({ size = 25 }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 50 50"
			width={size}
			height={size}
		>
			<path
				d="M33.617 4H16.383C9.555 4 4 9.555 4 16.383v17.234C4 40.445 9.555 46 16.383 46h17.234C40.445 46 46 40.445 46 33.617V16.383C46 9.555 40.445 4 33.617 4zM30 30.387c0 .89-.723 1.613-1.613 1.613H15.004A4.004 4.004 0 0111 27.996v-9.383c0-.89.723-1.613 1.613-1.613h13.383A4.004 4.004 0 0130 21.004zm9-.192a1 1 0 01-1.531.848l-5-3.125A1.008 1.008 0 0132 27.07v-5.14c0-.344.18-.664.469-.848l5-3.125a1 1 0 011.531.848zm0 0"
				fill="#fff"
			/>
		</svg>
	);
}

export default ZoomSvg;
