import React from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getIsLoggedIn } from "../selectors";

const PublicRoute = ({ isLoggedIn, ...props }) => {
	return isLoggedIn
		? <Redirect to="/dashboard" />
		: <Route {...props} />;
};

const mapStateToProps = (state) => ({
	isLoggedIn: getIsLoggedIn(state)
});

export default connect(mapStateToProps)(PublicRoute);
