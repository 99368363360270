import { SvgIcon } from '@material-ui/core';
import React from 'react';

export const Logo = props => (
	<SvgIcon {...props} viewBox="0 0 1500 1700">
		<g>
			<path d="M88 1553 c-24 -4 -28 -9 -28 -37 0 -18 10 -68 21 -112 12 -43 24 -91
26 -106 3 -16 9 -28 14 -28 5 0 9 -5 9 -11 0 -32 85 -145 176 -234 109 -106
121 -116 233 -194 l72 -49 -123 -88 c-209 -149 -312 -264 -372 -418 -30 -74
-56 -182 -56 -233 l0 -33 69 0 c39 0 72 4 75 9 3 5 12 43 20 85 19 99 44 154
105 229 103 130 215 223 402 335 l35 21 77 -49 c42 -27 77 -54 77 -59 0 -5
-52 -11 -118 -13 l-118 -3 -82 -59 c-45 -33 -82 -63 -82 -68 0 -4 136 -8 303
-8 l303 0 30 -32 c17 -18 41 -46 53 -63 l23 -30 -427 -5 -427 -5 -31 -57 c-26
-47 -30 -59 -19 -70 10 -10 114 -14 470 -19 251 -4 468 -8 482 -10 22 -4 26
-11 34 -66 l10 -63 79 0 79 0 -7 42 c-21 137 -76 283 -140 374 -86 120 -197
210 -514 411 -271 173 -397 267 -461 344 -87 105 -114 165 -147 334 -8 39 -9
40 -53 41 -25 1 -57 -1 -72 -3z"/>
			<path d="M1315 1492 l-7 -70 -493 -4 c-271 -1 -497 -7 -502 -11 -4 -5 4 -31
20 -58 15 -27 27 -55 27 -63 0 -8 7 -17 16 -20 9 -3 205 -6 435 -6 l420 0 -20
-27 c-11 -16 -32 -43 -48 -61 l-28 -33 -302 -6 c-225 -5 -303 -9 -303 -18 0
-11 40 -41 139 -107 19 -14 49 -18 116 -19 50 -1 103 -4 119 -8 l29 -6 -41
-29 c-23 -16 -42 -35 -42 -42 0 -17 89 -83 111 -84 29 0 214 146 301 239 52
56 99 117 121 158 57 110 106 292 88 322 -4 7 -39 15 -78 18 l-71 6 -7 -71z"/>
		</g>
	</SvgIcon>
);
