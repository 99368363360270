import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showCancelPremiumModal, showGoPremiumModal } from "../../actions";
import { getCompany, hasPremiumFeature, isDesktop } from "../../selectors";
import { EModalType } from "./EModalType";
import "./styles/NewBusinessModelSplashModal.scss";
import Box from "@material-ui/core/Box";

const CreditCardNeededModal = ({ open }) => {
	const isDesktop0 = useSelector(isDesktop);
	const hasPremium = useSelector(hasPremiumFeature);
	const dispatch = useDispatch();
	const company = useSelector(getCompany)

	const onClose = () => dispatch(hideModal(EModalType.NEW_FEATURE_WORKPLACES));

	if (!hasPremium)
		return null;

	return (
		<Dialog onClose={onClose} open={open}>
			{/*<Typography align="center" styleName="dialog-root">{"עדכון אמצעי תשלום נדרש 🫣"}</Typography>*/}
			<DialogContent styleName={`content ${isDesktop0 ? "desktop" : ""}`}>
				<Typography component="div">
					<Box fontSize={19} align="center">היי, לא הצלחנו לסלוק את התשלום החודשי</Box>
					<Box fontSize={19} align="center">תוכל בבקשה לעדכן את אמצעי התשלום? 💳</Box>
				</Typography>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center', paddingBottom: 25 }}>
				<Button variant="contained" onClick={() => dispatch(showGoPremiumModal(true))} color="primary">קח אותי
					לשם</Button>
				<Button variant="contained" onClick={() => dispatch(showCancelPremiumModal(company))}
					style={{ marginLeft: 100 }}>סיים מנוי</Button>
				<Button variant="contained" onClick={() => dispatch(hideModal())}>לא כרגע</Button>
			</DialogActions>
		</Dialog>
	);
};
export default CreditCardNeededModal;
