import * as FileSaver from "file-saver";
import { EXCEL, INNOVATION_AUTHORITY, MICHPAL, OKETZ_ADAM, OKETZ_EXCEL, OKETZ_MM } from "../../models/EReportFormat";
import { GAAction } from "../helpers/GAService";
import * as actions from "./actionTypes";
import { API } from "./actionTypes";
import { EModalType } from "../components/modals/EModalType";

export const fetchMonthlyReportSuccess = (payload) => ({
	type: actions.FETCH_MONTHLY_REPORT_SUCCESS,
	payload
});

export const updateMonthlyReportSuccess = (payload) => ({
	type: actions.UPDATE_MONTHLY_REPORT_SUCCESS,
	payload
});

export const fetchTasksReportSuccess = (payload) => ({
	type: actions.FETCH_TASKS_REPORT_SUCCESS,
	payload
});

const generateFileName = (month, year, companySettings, format) => {
	switch (format) {
	case MICHPAL: {
		const monthStr = month.toString().padStart(2, '0');
		const yearStr = year.toString().substr(year.toString().length - 2);

		return `מיכפל-${monthStr}${yearStr}.csv`;
	}
	case OKETZ_MM: {
		const oketzCompanyId = companySettings.oketzSettings.companyId.toString().padStart(5, '0');

		return `sal${oketzCompanyId}.mm`;
	}
	case OKETZ_ADAM: {
		return `adam.dat`;
	}
	case OKETZ_EXCEL:
	case INNOVATION_AUTHORITY:
	case EXCEL:
		return `דוח-שעות-${month}-${year}.xlsx`;
	}
};

export const exportReport = (month, year, companySettings, format) => {
	const fileNameFormat = format || companySettings.defaultExportFormat;
	const fileName = generateFileName(month, year, companySettings, fileNameFormat);

	return {
		type: actions.API,
		payload: {
			url: `/reports/download?year=${year}&month=${month}&format=${fileNameFormat}`,
			method: "get",
			responseType: 'blob',

			success: (data) => {
				let blob = new Blob([data], { type: data.type });
				FileSaver.saveAs(blob, fileName);
			},
		},
		meta: {
			shouldAuthenticate: true,
		},
		ga: {
			action: GAAction.DOWNLOAD_EXCEL,
			category: fileNameFormat
		}
	};
};

export const fetchMonthlyReport = (month, year) => ({
	type: actions.API,
	payload: {
		url: `/reports/monthly?year=${year}&month=${month}`,
		method: "get",
		success: fetchMonthlyReportSuccess,
	},
	meta: {
		shouldAuthenticate: true,
	}
});

export const fetchTasksReport = (month, year) => ({
	type: actions.API,
	payload: {
		url: "/reports/tasks?year=" + year + "&month=" + month,
		method: "get",
		success: fetchTasksReportSuccess,
	},
	meta: {
		shouldAuthenticate: true,
	}
});

export const managerApproveMonthlyReport = (employeeId, month, year, cancelApproval) => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.YES_NO_MODAL,
		modalProps: {
			open: true,
			onAction: () => ({
				type: API,
				payload: {
					url: `/reports/approve?year=${year}&month=${month}${cancelApproval ? "&cancelApproval=true" : ""}`,
					method: "post",
					success: updateMonthlyReportSuccess,
					data: {
						employeeId
					}
				},
				meta: {
					shouldAuthenticate: true,
				}
			}),
			text: `האם אתה בטוח שברצונך ${cancelApproval ? "לבטל" : "לאשר"} את דוח החודש ${month}/${year}?`
		}
	}
});
