import { routerReducer } from "react-router-redux";
import { combineReducers } from 'redux';
import { GeneralReducer } from "./GeneralReducer";
import { LoaderReducer } from "./LoaderReducer";
import { ModalReducer } from "./ModalReducer";
import { PaymentReducer } from './PaymentReducer';
import { ReportsReducer } from "./ReportsReducer";
import { ShiftsReducer } from "./ShiftsReducer";
import { TasksReducer } from "./TasksReducer";
import { UserReducer } from "./UserReducer";
import { UsersReducer } from "./UsersReducer";


const rootReducer = combineReducers({
	loader: LoaderReducer,
	routing: routerReducer,
	users: UsersReducer,
	shifts: ShiftsReducer,
	tasks: TasksReducer,
	user: UserReducer,
	modal: ModalReducer,
	reports: ReportsReducer,
	general: GeneralReducer,
	payment: PaymentReducer,
});

export default rootReducer;
