/**
 * Created by Chen on 16/07/2017.
 */

import { Checkbox, CircularProgress, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';
import { ELoginRegisterMode } from "./ELoginRegisterMode";
import ForgotPasswordLink from "./ForgotPasswordLink";
import "./styles/LoginRegister.scss";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const RegisterDialogContent = ({
	isLoading,
	errors,
	backEndError,
	handleChange,
	handleSubmit,
	onChangeMode,
	isSubmitDisabled,
	handleKeyPress
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);

	return (
		<>
			<DialogTitle>ברוך הבא!</DialogTitle>
			<DialogContent>
				<TextField
					label={"שם משתמש"}
					placeholder={"שם משתמש"}
					name="username"
					autoComplete="username"
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					fullWidth
					autoFocus
					error={!!errors["username"]}
					helperText={errors["username"]}
					inputProps={{ "data-hj-allow": "" }}
				/>
				<TextField
					label={"אימייל"}
					placeholder={"אימייל"}
					type={"email"}
					name="email"
					autoComplete="email"
					error={!!errors["email"]}
					helperText={errors["email"]}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					fullWidth
					inputProps={{ "data-hj-allow": "" }}
				/>
				<TextField
					label="סיסמא"
					placeholder="סיסמא"
					type={showPassword ? "text" : "password"}
					name="password"
					autoComplete="current-password"
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					error={!!errors["password"]}
					helperText={errors["password"]}
					fullWidth
					InputProps={{
						endAdornment:
							<InputAdornment position="end" styleName="password-icon">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
								>
									{showPassword ? <VisibilityOff/> : <Visibility/>}
								</IconButton>
							</InputAdornment>
					}}
				/>
				<TextField
					label="אימות סיסמא"
					placeholder="אימות סיסמא"
					type={showPassword ? "text" : "password"}
					name="retypePassword"
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					autoComplete="new-password"
					error={!!errors["retypePassword"]}
					helperText={errors["retypePassword"]}
					fullWidth
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={acceptTerms}
							onChange={() => setAcceptTerms(!acceptTerms)}
							color="primary"
						/>
					}
					label={
						<span>
          קראתי והבנתי את{" "}
							<a href="https://docs.google.com/document/d/1vGWUvLTo2izTOBTmJBpjkKPwm4bpRkVGV9IsyJSxVzw/edit?usp=sharing"
							   target="_blank" rel="noopener noreferrer">
            תנאי השימוש
							</a>
						</span>
					}
				/>
				{backEndError && <Typography styleName="error-msg">{backEndError}</Typography>}
				<div styleName="login-register-footer">
					<Button variant="contained" color="primary" type="submit" styleName="login-button"
						disabled={isSubmitDisabled || !acceptTerms}
						onClick={handleSubmit}>
						<Typography styleName="button-text">הירשם</Typography>
						{isLoading ? <CircularProgress size={15} style={{ color: "white" }}/> : <ArrowBackIcon/>}
					</Button>
					<div styleName="footer-text">
						<Typography styleName="question">נרשמת כבר?</Typography>
						<Typography styleName="change-mode" onClick={() => onChangeMode(ELoginRegisterMode.LOGIN)}>היכנס
							עכשיו</Typography>
					</div>
					<ForgotPasswordLink onClick={() => onChangeMode(ELoginRegisterMode.FORGOT_PASSWORD)}/>
				</div>
			</DialogContent>
		</>
	);
};

export default RegisterDialogContent;
