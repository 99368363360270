import { DESKTOP, FETCH_META_DATA_SUCCESS, IS_LOGGED_IN, MOBILE } from "../actions/actionTypes";

const initialState = {};

export function GeneralReducer(state = initialState, action) {
	switch (action.type) {
	case FETCH_META_DATA_SUCCESS:
		return {
			...state,
			meta: action.payload
		};
	case DESKTOP:
		return {
			...state,
			isDesktop: true
		};
	case MOBILE:
		return {
			...state,
			isDesktop: false
		};
	case IS_LOGGED_IN:
		return {
			...state,
			isLoggedIn: action.payload
		};
	default:
		return state;
	}
}
