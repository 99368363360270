import * as Sentry from "@sentry/react";
import { datadogRum } from '@datadog/browser-rum';
import '@datadog/browser-logs/bundle/datadog-logs'

Sentry.init({
	dsn: "https://4bb33fa2bbb4ded7bedcaa95f19fc71d@o4507889072537600.ingest.us.sentry.io/4508145845403648",
	environment: process.env.NODE_ENV,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
		Sentry.captureConsoleIntegration()
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/(www\.)?meeba\.co\.il/],
	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// results in 25% of transactions being profiled (0.5*0.5=0.25)
	profilesSampleRate: 1.0,
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


datadogRum.init({
	applicationId: '54583af8-58a2-4f68-8171-26842138ce1d',
	clientToken: 'pub3f43f91e997680c87e1b1cfab21f097b',
	// `site` refers to the Datadog site parameter of your organization
	// see https://docs.datadoghq.com/getting_started/site/
	site: 'datadoghq.eu',
	service: 'meeba-web',
	env: process.env.NODE_ENV,
	// Specify a version number to identify the deployed version of your application in Datadog
	// version: '1.0.0',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 20,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
});

window.DD_LOGS.init({
	clientToken: 'pub3f43f91e997680c87e1b1cfab21f097b',
	site: 'datadoghq.eu',
	forwardErrorsToLogs: true,
	sessionSampleRate: 100,
})
