import Link from "@material-ui/core/Link";
import React from 'react';
import './styles/Footer.scss';
import WhatsAppSvg from "./styles/WhatsAppSvg";
import { generateWhatsAppLink, whatsAppLink } from "../whatsapp/constants";

const Footer = () => {
	return (
		<div styleName="footer">
			<Link href={whatsAppLink} target="_blank">
				<span>
					<span styleName="weak"> שאלה? בעיה?</span>
					<span styleName="contact-us">צור קשר איתנו ב-</span>
					<WhatsAppSvg/>
				</span>
			</Link>
			<Link target="_blank" rel="noopener noreferrer"
				  href={generateWhatsAppLink("היי, יכול להיות שמצאתי בעיה באתר: ")}>
				פניה לאבטחת מידע
			</Link>
			<Link target="_blank" rel="noopener noreferrer"
				  href="https://docs.google.com/document/d/1vGWUvLTo2izTOBTmJBpjkKPwm4bpRkVGV9IsyJSxVzw/edit?usp=sharing">
				תנאי שימוש
			</Link>
		</div>
	);
};

export default Footer;
