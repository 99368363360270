import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../actions";
import { isDesktop, isReportApprovalEnable } from "../../selectors";
import { EModalType } from "./EModalType";
import "./styles/ReportApprovalModal.scss";
import Box from "@material-ui/core/Box";
import managerApproval from "./assets/manager-approval.png";
import employeeApproval from "./assets/employee-approval.png";

const ReportApprovalModal = ({ open }) => {
	const isDesktop0 = useSelector(isDesktop);
	const reportApprovalEnable = useSelector(isReportApprovalEnable);
	const dispatch = useDispatch();

	const onClose = () => dispatch(hideModal(EModalType.REPORT_APPROVAL));

	if (!reportApprovalEnable)
		return null;

	return (
		<Dialog onClose={onClose} open={open}>
			<DialogContent styleName={`content ${isDesktop0 ? "desktop" : ""}`}>
				<Typography component="div">
					<Box fontSize={21} marginBottom={4} fontWeight={600} textAlign="center">חדש! ✨ תמיכה בחתימה דיגיטלית
						בדוח השעות
						החודשי</Box>
					<Box fontSize={16} fontWeight={600} marginBottom={1} textAlign="left">העובד חותם במובייל ← דוח שעות
						← חתימת
						עובד</Box>
					<Box justifyContent={"center"} display={"flex"}>
						<img src={employeeApproval}/>
					</Box>
					<Box fontSize={16} fontWeight={600} marginTop={4} marginBottom={1} textAlign="left">המנהל חותם באתר
						← דוח
						שעות ←
						חודשי ← חתימת מנהל
					</Box>
					<Box justifyContent={"center"} display={"flex"}>
						<img src={managerApproval}/>
					</Box>
				</Typography>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center', paddingBottom: 25 }}>
				<Button variant="contained" onClick={() => dispatch(hideModal())} color="primary">הבנתי, תודה!</Button>
			</DialogActions>
		</Dialog>
	);
};
export default ReportApprovalModal;
