/**
 * Created by Chen on 16/07/2017.
 */

import React, { useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmail, isEmptyObject, isValidEmail } from "../../../managers/utils";
import { handleRegister, hideLoginRegisterModal } from "../../actions";
import * as firebaseApi from "../../helpers/firebaseApi";
import { fetchEmail } from "../../helpers/meebaApi";
import { ELoginRegisterMode } from "./ELoginRegisterMode";
import LoginRegister from "./LoginRegister";
import "./styles/LoginRegister.scss";

const LoginRegisterContainer = ({ open, hideLoginRegisterModal, handleRegister }) => {
	const [loginRegisterMode, setLoginRegisterMode] = useState(ELoginRegisterMode.LOGIN);
	const [backEndError, setBackEndError] = useState("");
	const [dirty, setDirty] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [values, setValues] = useState({});

	const handleChangeMode = (mode) => {
		setDirty({});
		setLoginRegisterMode(mode);
	};

	const handleClose = () => {
		setDirty({});

		hideLoginRegisterModal();
	};

	const onRegister = values => {
		return handleRegister(
			// values, 
			{
				username: values.username,
				email: values.email,
				password: values.password
			},
			() => {
				setIsLoading(false);
				handleClose();
			},
			(err) => {
				setIsLoading(false);
				handleBackEndError(err);
			}
		);
	};

	const onLogin = async values => {
		const { identifier, password } = values;
		let email;

		try {
			// Check if not email bring the email
			if (isEmail(identifier))
				email = identifier;
			else {
				email = await fetchEmail(identifier);
			}

			await firebaseApi.login(email, password);
			handleClose();
		} catch (err) {
			handleBackEndError(err);
		} finally {
			setIsLoading(false);
		}
	};
    
	const handleBackEndError = err => {
		if (typeof err === "string")
			setBackEndError(err);
		else if (err?.response?.data?.message) {
			setBackEndError(err?.response?.data?.message    );
		}
		else if (err?.message) {
			setBackEndError(err.message);
		}
	};
    
	const onSubmit = () => {
        
		setIsLoading(true);

		if (loginRegisterMode === ELoginRegisterMode.LOGIN)
			onLogin(values);
		else 
			onRegister(values);
	};

	const calcRegisterErrors = errors => {
		const { username, email, password, retypePassword } = values;

		if (dirty.username && !username)
			errors = { username: "שם משתמש חסר" };
		else if (dirty.email && !email)
			errors = { email: "אימייל חסר" };
		else if (dirty.email && !isValidEmail(email))
			errors = { email: "אימייל לא תקין" };
		else if (dirty.password && !password)
			errors = { password: "סיסמא חסרה" };
		else if (dirty.password && password.length < 6)
			errors = { password: "אורך סיסמא לפחות 6 תוים" };
		else if (dirty.retypePassword && !retypePassword)
			errors = { retypePassword: "סיסמא חסרה" };
		else if (dirty.password && dirty.retypePassword && password !== retypePassword)
			errors = { password: "סיסמא לא זהה", retypePassword: "סיסמא לא זהה" };
		return errors;
	};

	const calcLoginErrors = errors => {
		const { identifier, password } = values;

		if (dirty.identifier && !identifier)
			errors = { identifier: "שם משתמש או אימייל חסר" };
		else if (dirty.password && !password)
			errors = { password: "סיסמא חסרה" };
		return errors;
	};

	const calcErrors = () => {
		let errors = {};
		switch (loginRegisterMode) {
		case ELoginRegisterMode.LOGIN:
			errors = calcLoginErrors(errors);
			break;
		case ELoginRegisterMode.REGISTER:
			errors = calcRegisterErrors(errors);
			break;
		}
        
		return errors;
	};
    
	const handleChange = (event) => {
		const { name, value } = event.target;
        
		setValues({
			...values,
			[name]: value,
		});
		setDirty({
			...dirty,
			[name]: true
		});
		handleBackEndError("");
	};
    
	const shouldEnableSubmit = () => {
		if (loginRegisterMode === ELoginRegisterMode.LOGIN)
			return isEmptyObject(errors) && Object.keys(dirty).length === 2;
		else
			return isEmptyObject(errors) && Object.keys(dirty).length === 4;
	};

	const errors = calcErrors();

	return (
		<LoginRegister
			loginRegisterMode={loginRegisterMode}
			isLoading={isLoading}
			open={open}
			errors={errors}
			backEndError={backEndError}
			handleChange={handleChange}
			handleSubmit={onSubmit}
			handleClose={handleClose}
			onChangeMode={handleChangeMode}
			isSubmitDisabled={!shouldEnableSubmit()}
		/>
	);
};

LoginRegisterContainer.propTypes = {
};

const mapDispatchToProps = {
	hideLoginRegisterModal,
	handleRegister
};

export default connect(null, mapDispatchToProps)(withRouter(LoginRegisterContainer));

