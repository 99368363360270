import * as types from "../actions/actionTypes";

const initialState = [];

export function ReportsReducer(state = initialState, action) {
	switch (action.type) {
	case types.FETCH_MONTHLY_REPORT_SUCCESS:
		return {
			employeesMonthlyReports: action.payload
		};
	case types.UPDATE_MONTHLY_REPORT_SUCCESS:
		let employeesMonthlyReports = state.employeesMonthlyReports.map(report => (report?.reportApproval?._id !== action.payload._id) ? report :
			{
				...report,
				reportApproval: action.payload
			}
		);
		return {
			employeesMonthlyReports
		};
	case types.FETCH_TASKS_REPORT_SUCCESS:
		return {
			tasksReports: action.payload
		};
	default:
		return state;
	}
}
