import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { showLoginRegisterDialog } from "../../actions";
import * as selectors from "../../selectors";
import "../../styles/HomeAppBar.scss";
import facebookImage from "../../styles/images/facebook.png";
import { Logo } from "../../styles/Logo";
import { whatsAppLink } from "../whatsapp/constants";
import WhatsAppSvg from "./styles/WhatsAppSvg";
import ZoomSvg from "./styles/ZoomSvg";

const HomeAppBar = ({ isDesktop, location }) => {
	const [isTop, setIsTop] = useState(true);

	useEffect(() => {
		document.addEventListener('scroll', topScrollListener);

		return () =>  document.removeEventListener('scroll', topScrollListener);
	});

	const topScrollListener = () => {
		const isTop0 = window.scrollY < 60;
		if (isTop0 !== isTop)
			setIsTop(isTop0);
	};

	const isTopClass = isTop ? 'isTop' : '';

	return (
		<AppBar position="fixed" styleName={`home-app-bar ${isTopClass}`}>
			<Toolbar styleName={`toolbar ${isTopClass}`}>
				<div styleName="right-buttons-group">
					<Link to="/dashboard" styleName="link logo">
						<IconButton aria-label="Menu" color="inherit">
							<Logo/>
							<Typography type="title" color="inherit" style={{ paddingRight: "10px" }}>
                                מיבא
							</Typography>
						</IconButton>
					</Link>
					<a target={'_blank'} href={whatsAppLink} styleName="link" rel="noopener noreferrer">
						<WhatsAppSvg />
						{isDesktop && <Typography styleName="linkText">נדבר בצ'אט?</Typography>}
					</a>
					<Link color="inherit" styleName="link" href="#" onClick={() => {
						// eslint-disable-next-line no-undef
						Calendly.initPopupWidget({ url: 'https://calendly.com/chenop/meeba?primary_color=b27479' });
					}}>
						<ZoomSvg />
						{isDesktop && <Typography styleName="linkText">ניפגש בזום?</Typography>}
					</Link>
					<a href="https://www.facebook.com/meebaOnFace/" styleName="link" target="_blank" rel="noopener noreferrer">
						<img src={facebookImage}/>
						{isDesktop && <Typography styleName="linkText">פייסבוק</Typography>}
					</a>
				</div>
				<div>
					{location.pathname !== '/faq' &&
                    <Button color="inherit"><Link to="/faq" styleName="faq">שאלות ותשובות</Link></Button>
					}
				</div>
			</Toolbar>
		</AppBar>
	);
};

const mapStateToProps = (state) => ({
	isDesktop: selectors.isDesktop(state)
});

const mapDispatchToProps = {
	showLoginRegisterDialog,
};

// withStyles() is needed here for the component to not crash in production - not sure why... :(
export default connect(mapStateToProps, mapDispatchToProps)(withStyles()(withRouter(HomeAppBar)));
