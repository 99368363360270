import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { fetchMetaData } from "../../actions/generalActions";
import * as selectors from "../../selectors";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header";
import Statistics from "./Statistics";
import "./styles/Home.scss";
import Videos from "./Videos";

const Home = ({ isDesktop, fetchMetaData }) => {
	useEffect(() => {
		fetchMetaData();
	}, []);

	return (
		<div styleName="home">
			<div styleName="home-bg">
				<Header/>
				{isDesktop && <Videos/>}
				<Features/>
				<Statistics />
				<Footer />
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	fetchMetaData,
};

const mapStateToProps = state => ({
	isDesktop: selectors.isDesktop(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
