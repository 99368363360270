import { EModalType } from "../components/modals/EModalType";
import { DEFAULT_IA_TASK_TITLE, DEFAULT_NON_IA_TASK_TITLE } from "../components/user/constants";
import { API, UPDATE_ACTIVE_USER_SUCCESS, } from "./actionTypes";
import { createTask } from "./tasksActions";

export const updateActiveUserSuccess = (payload) => ({
	type: UPDATE_ACTIVE_USER_SUCCESS,
	payload
});

export const showEditEmployeeModal = (employee) => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.EDIT_EMPLOYEE,
		modalProps: {
			entity: employee,
			open: true,
			key: employee._id
		}
	}
});

export const showLoginRegisterDialog = () => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.LOGIN_REGISTER,
		modalProps: {
			open: true,
		}
	}
});

export const updateActiveUser = (user) => ({
	type: API,
	payload: {
		url: "/users",
		method: "put",
		data: user,
		success: updateActiveUserSuccess,
	},
	meta: {
		shouldAuthenticate: true,
		debounce: {
			time: 700
		}
	}
});


export const showDeleteUserModal = (user, deleteUser) => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.DELETE_EMPLOYEE,
		modalProps: {
			entity: user,
			deleteEntity: deleteUser,
			open: true
		}
	}
});

export const showMobileAppModal = () => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.MOBILE_APP_LINKS,
		modalProps: {
			open: true
		}
	}
});

export const showAddDefaultIATasksModal = (tasks, companyId) => ({
	type: 'SHOW_MODAL',
	payload: {
		modalType: EModalType.YES_NO_MODAL,
		modalProps: {
			open: true,
			onAction: () => addDefaultIATasks(tasks, companyId),
			text: "תרצה להוסיף את המשימות הבאות: 'מחקר ופיתוח' ו-'אחר'?"
		}
	}
});

const addDefaultIATasks = (tasks, companyId) => dispatch => {
	const shouldAddDefaultIATask = !(tasks.find(t => t.title === DEFAULT_IA_TASK_TITLE));
	const shouldAddNoneIaTask = !(tasks.find(t => t.title === DEFAULT_NON_IA_TASK_TITLE));

	if (shouldAddDefaultIATask) {
		dispatch(createTask({
			title: DEFAULT_IA_TASK_TITLE,
			isInnovative: true,
			company: companyId
		}));
	}

	if (shouldAddNoneIaTask) {
		dispatch(createTask({
			title: DEFAULT_NON_IA_TASK_TITLE,
			isInnovative: false,
			company: companyId
		}));
	}
};

