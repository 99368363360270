import React from 'react';
import "./styles/SectionTitle.scss";

const SectionTitle = ({ title }) => {
	return (
		<div styleName="title">{title}</div>
	);
};

export default SectionTitle;
