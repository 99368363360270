import React, { Component } from 'react';
import HomeAppBar from '../home/HomeAppBar';
import "./styles/FAQHeader.scss";

class FAQHeader extends Component {

	render() {
		return (
			<div styleName="header">
				<div styleName="header-bg">
					<HomeAppBar />
					<div styleName="title">
                        שאלות ותשובות
					</div>
				</div>
			</div>
		);
	}
}

export default FAQHeader;
