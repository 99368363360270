import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getIsLoggedIn } from "../selectors";
import FullScreenLoading from "./FullScreenLoading";

const DashboardProtectedRoute = ({ isLoggedIn, ...props }) => {
	return isLoggedIn
		? <Suspense fallback={<FullScreenLoading />}>
			<Route {...props} />
		</Suspense>
		: <Redirect to="/home" />;
};

const mapStateToProps = (state) => ({
	isLoggedIn: getIsLoggedIn(state)
});

export default connect(mapStateToProps)(DashboardProtectedRoute);
