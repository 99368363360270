/**
 * Created by Chen on 16/07/2017.
 */

import Dialog from "@material-ui/core/Dialog";
import React from 'react';
import { ELoginRegisterMode } from "./ELoginRegisterMode";
import ForgotPasswordContent from "./ForgotPasswordContent";
import LoginDialogContent from "./LoginDialogContent";
import RegisterDialogContent from "./RegisterDialogContent";
import "./styles/LoginRegister.scss";

const LoginRegister = ({
	loginRegisterMode, isLoading, open, errors, backEndError, handleClose,
	handleChange, handleSubmit, onChangeMode, isSubmitDisabled
}) => {
	const handleKeyPress = event => {
		if (event.key === 'Enter' && !isSubmitDisabled) {
			handleSubmit();
			event.preventDefault();
		}
	};
    
	const onForgotPasswordClose = () => {
		onChangeMode(ELoginRegisterMode.LOGIN);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<div styleName="login-register">
				{loginRegisterMode === ELoginRegisterMode.FORGOT_PASSWORD &&
                        <ForgotPasswordContent handleKeyPress={handleKeyPress} onClose={onForgotPasswordClose}/>
				}
				{loginRegisterMode === ELoginRegisterMode.LOGIN && 
                    <LoginDialogContent
                    	isLoading={isLoading}
                    	errors={errors}
                    	backEndError={backEndError}
                    	handleChange={handleChange}
                    	handleSubmit={handleSubmit}
                    	onChangeMode={onChangeMode}
                    	isSubmitDisabled={isSubmitDisabled}
                    	handleKeyPress={handleKeyPress}
                    />
				}
				{loginRegisterMode === ELoginRegisterMode.REGISTER &&
                    <RegisterDialogContent
                    	isLoading={isLoading}
                    	errors={errors}
                    	backEndError={backEndError}
                    	handleChange={handleChange}
                    	handleSubmit={handleSubmit}
                    	onChangeMode={onChangeMode}
                    	isSubmitDisabled={isSubmitDisabled}
                    	handleKeyPress={handleKeyPress}
                    />
				}
			</div>
		</Dialog>
	);
};

export default LoginRegister;
