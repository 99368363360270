import React from 'react';
import whatsAppImage from "./assets/whatsapp.png";
import { whatsAppLink } from "./constants";
import './styles/WhatsAppBadge.scss';

const WhatsAppBadge = () => {
	return (
		<a href={whatsAppLink} target="_blank" rel="noopener noreferrer">
			<img src={whatsAppImage} loading="lazy" styleName="badge"/>
		</a>
	);
};

export default WhatsAppBadge;
