import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../actions";
import { hasPremiumFeature, isDesktop } from "../../selectors";
import { EModalType } from "./EModalType";
import "./styles/NewBusinessModelSplashModal.scss";

const NewBusinessModelSplashModal = ({ open }) => {
	const isDesktop0 = useSelector(isDesktop);
	const hasPremium = useSelector(hasPremiumFeature);
	const dispatch = useDispatch();

	const onClose = () => dispatch(hideModal(EModalType.NEW_FEATURE_WORKPLACES));

	if (!hasPremium)
		return null;

	return (
		<Dialog onClose={onClose} open={open}>
			<Typography align="center" styleName="dialog-root">{"עדכון בתמחור המנויים"}</Typography>
			<DialogContent styleName={`content ${isDesktop0 ? "desktop" : ""}`}>
				<Typography component="div">
					<Box fontSize={16} textAlign="left">הטבת הקורונה תפוג בקרוב ודמי המנוי יתעדכו באופן הבא:</Box>
					<Box fontSize={21} fontWeight={600} textAlign="center">6 ש"ח לעובד לחודש</Box>
					<Box paddingTop={4} paddingBottom={1} fontSize={16} fontWeight={500} textAlign="left">גאים על כך שעמדנו לצד העסקים הקטנים בתקופה זו 💪🏻</Box>
				</Typography>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'center', paddingBottom: 25 }}>
				<Button variant="contained" onClick={() => dispatch(hideModal())} color="primary">הבנתי, תודה!</Button>
			</DialogActions>
		</Dialog>
	);
};
export default NewBusinessModelSplashModal;
