import axios from "axios";
import { clearUser, HideLoading, ShowLoading } from "../actions";
import * as actionTypes from "../actions/actionTypes";
import { API_URL } from "../config";
import { getToken } from "../helpers/firebaseApi";

import history from "../helpers/historyService";

const apiMiddleware = ({ dispatch }) => next => async action => {

	if (action.type !== actionTypes.API) {
		return next(action);
	}

	let headers = {};
	if (action.meta && action.meta.shouldAuthenticate) {
		const token = await getToken();
		if (token) {
			headers['Authorization'] = `Bearer ${token}`;
		}
	}

	const companyName = localStorage.getItem('company');
	if (companyName)
		headers['Client-Info'] = escape(companyName);

	let { url, success, onError, method, data, responseType } = action.payload;

	url = `${API_URL}${url}`;

	dispatch(ShowLoading());
	axios({
		url,
		timeout: 20000,
		method,
		data,
		headers,
		responseType,
	}).then(response => {
		dispatch(HideLoading());
		const successAction = success(response.data);
		return successAction ? dispatch(successAction) : null;
	})
		.catch((err) => {
			dispatch(HideLoading());
			console.error(err);
			const { response: { data, status } = {} } = err;

			if (data?.message)
				console.error(data?.message);
			if (onError)
				return dispatch(onError(data));
			else {
				switch (status) {
				case 403:
					dispatch(clearUser(err));
					history.push("/home");
				}
			}
		});

	return next(action);
};

export default apiMiddleware;
