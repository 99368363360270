import * as firebaseApi from "../helpers/firebaseApi";
import { loginToFirebaseWithToken } from "../helpers/firebaseApi";
import { GAAction } from "../helpers/GAService";
import history from "../helpers/historyService";
import * as actionsTypes from "./actionTypes";
import { setIsLoggedIn } from "./generalActions";

const registerUserSuccess = user => ({
	type: actionsTypes.REGISTER_SUCCESS,
	payload: user
});

// const loginUserSuccess = user => ({
//     type: actionsTypes.LOGIN_SUCCESS,
//     payload: user
// });

export const registerUserFailure = error => ({
	type: actionsTypes.REGISTER_FAILURE,
	payload: error
});

export const handleRegister = (data, onSuccess, onError) => async dispatch => {
	const { email, username, password } = data;

	try {
		const registerData = {
			email,
			username,
			password
		};
		dispatch(register(registerData, onSuccess, onError));
	}
	catch(err) {
		if (onError)
			onError(err);
	}
};

export const register = (data, onSuccess, onError) => ({
	type: actionsTypes.API,
	payload: {
		url: "/register",
		method: "post",
		data,
		success: result => async dispatch => {
			dispatch(registerUserSuccess(result.user));
			await loginToFirebaseWithToken(result.token);
			if (onSuccess)
				onSuccess();
		},
		onError: err => dispatch => {
			dispatch(registerUserFailure(err));
			if (onError)
				onError(err);
		}
	},
	ga: {
		action: GAAction.REGISTER,
	}
});

export const handleLogout = () => dispatch => {
	dispatch(clearUser());
	dispatch(setIsLoggedIn(false));
	firebaseApi.logout();
};

export const navigateHome = () => {
	history.push('/');
};

export function meFromTokenSuccess(currentUser) {
	return {
		type: actionsTypes.ME_FROM_TOKEN_SUCCESS,
		payload: currentUser
	};
}

export const clearUser = error => ({
	type: actionsTypes.CLEAR_USER,
	payload: error
});

export const authenticate = (onSuccess, onError) => ({
	type: actionsTypes.API,
	payload: {
		url: '/authenticate',
		method: 'get',
		success: result => dispatch => {
			dispatch(meFromTokenSuccess(result.user));
			if (onSuccess)
				onSuccess();
		},
		onError: (err) => dispatch => {
			// do logout()
			dispatch(clearUser(err));
			if (onError)
				onError();
		}
	},
	meta: {
		shouldAuthenticate: true
	}
});
