const getComputeDistanceBetween2 = (location1, location2) => {
	const latlngA = {
		...location1,
		lat: location1.latitude,
		lng: location1.longitude
	};
	const latlngB = {
		...location2,
		lat: location2.latitude,
		lng: location2.longitude
	};

	return getComputeDistanceBetween(latlngA, latlngB);
};

/**
 * Source: https://stackoverflow.com/a/51720402/1846993
 * Calculates the haversine distance between point A, and B.
 * @param {location {lng, lat} point A
 * @param {location {lng, lat} point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
const getComputeDistanceBetween = (latlngA, latlngB) => {
	const squared = x => x * x;
	const toRad = x => (x * Math.PI) / 180;
	const R = 6371; // Earth’s mean radius in km

	const dLat = toRad(latlngB.lat - latlngA.lat);
	const dLon = toRad(latlngB.lng - latlngA.lng);

	const dLatSin = squared(Math.sin(dLat / 2));
	const dLonSin = squared(Math.sin(dLon / 2));

	const a = dLatSin +
		(Math.cos(toRad(latlngA.lat)) * Math.cos(toRad(latlngB.lat)) * dLonSin);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let distance = R * c;

	return Math.floor(distance * 1000); // Convert to Meters
};

module.exports = {
	getComputeDistanceBetween2,
	getComputeDistanceBetween
};
