export const HANDLE_LOGIN_START = 'HANDLE_LOGIN_START';
export const HANDLE_LOGIN_SUCCESS = 'HANDLE_LOGIN_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS';
export const CLEAR_USER = 'CLEAR_USER';

export const CREATE_SHIFT_SUCCESS = 'CREATE_SHIFT_SUCCESS';
export const FETCH_SHIFT_SUCCESS = 'FETCH_SHIFT_SUCCESS';
export const FETCH_DAILY_SHIFTS_SUCCESS = 'FETCH_DAILY_SHIFTS_SUCCESS';
export const APPROVED_ALL_PENDING_SHIFTS_SUCCESS = 'APPROVED_ALL_PENDING_SHIFTS_SUCCESS';
export const UPDATE_SHIFT_SUCCESS = 'UPDATE_SHIFT_SUCCESS';
export const DELETE_SHIFT_SUCCESS = 'DELETE_SHIFT_SUCCESS';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_META_DATA_SUCCESS = 'FETCH_META_DATA_SUCCESS';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';

export const FETCH_PAYMENT_TOKEN_SUCCESS = 'FETCH_PAYMENT_TOKEN_SUCCESS';
export const FETCH_PAYMENT_URL_SUCCESS = 'FETCH_PAYMENT_URL_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const UPDATE_PAYMENT_STEP = 'UPDATE_PAYMENT_STEP';

export const UPDATE_ACTIVE_USER_SUCCESS = 'UPDATE_ACTIVE_USER_SUCCESS';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const FETCH_MONTHLY_REPORT_SUCCESS = 'FETCH_MONTHLY_REPORT_SUCCESS';
export const UPDATE_MONTHLY_REPORT_SUCCESS = 'UPDATE_MONTHLY_REPORT_SUCCESS';
export const FETCH_TASKS_REPORT_SUCCESS = 'FETCH_TASKS_REPORT_SUCCESS';

export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const FILTER_TASKS = 'FILTER_TASKS';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const DESKTOP = 'DESKTOP';
export const MOBILE = 'MOBILE';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const API = 'API';
export const Error = 'Error';

