export const EModalType = {
	EDIT_SHIFT: 1,
	EDIT_EMPLOYEE: 2,
	MOVING_SHIFT_OUT_OF_MONTH: 3,
	MOBILE_APP_LINKS: 4,
	LOCATION_MODAL: 5,
	LOGIN_REGISTER: 6,
	TASK_MODAL: 7,
	GO_PREMIUM_MODAL: 8,
	DELETE_ENTITY: 9,
	YES_NO_MODAL: 10,
	SURVEY_2019: 11,
	NEW_FEATURE_ABSENCE_DAYS: 12,
	COVID19_DISCOUNT: 13,
	WORKPLACE_SELECTION: 14,
	NEW_FEATURE_WORKPLACES: 15,
	NEW_BUSINESS_MODEL: 16,
	NEW_FEATURE_RECEIPTS: 17,
	CREDIT_CARD_NEEDED: 18,
	REPORT_APPROVAL: 19,
	DELETE_EMPLOYEE: 20
};
