import Typography from "@material-ui/core/Typography";
import React from 'react';
import "./styles/LoginRegister.scss";

const ForgotPasswordLink = ({ onClick }) => {
	return (
		<div onClick={onClick} styleName="change-mode">
			<Typography>שכחתי סיסמא</Typography>
		</div>
	)
	;
};

export default ForgotPasswordLink;
