import { DialogContent, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useState } from 'react';
import { sendPasswordResetEmail } from "../../helpers/firebaseApi";
import MbDialogTitle from "./MbDialogTitle";
import "./styles/LoginRegister.scss";

const ForgotPasswordContent = ({ handleKeyPress, onClose }) => {
	const [email, setEmail] = useState('');
	const [done, setDone] = useState(false);
	const [error, setError] = useState();

	const handleResetPassword = async () => {
		try {
			await sendPasswordResetEmail(email);
			setDone(true);
		} catch (err) {
			setError(err.toString());
		}
	};

	return (
		<>
			<MbDialogTitle onClose={onClose}/>
			<DialogContent>
				<div styleName="forgot-password">
					{!done &&
                        <>
                        	<Typography styleName="title">אנא הזן את כתובת הדוא"ל עימה נרשמת למערכת.</Typography>
                        	<TextField
                        		label={"אימייל"}
                        		placeholder={""}
                        		autoComplete="email"
                        		onChange={(e) => setEmail(e.target.value)}
                        		onKeyPress={handleKeyPress}
                        		fullWidth
                        		autoFocus
                        		error={false}
                        		helperText={""}
                        		styleName={'email'}
                        		inputProps={{ "data-hj-allow": "" }}
                        	/>
                        	{error && <Typography styleName="error-msg">{error}</Typography>}
                        	<Button variant="contained" color="primary" type="submit"
                        		disabled={!email}
                        		onClick={handleResetPassword}>
                        		<Typography>שלח</Typography>
                        	</Button>
                        </>
					}
					{done &&
                        <Typography styleName="title">אנא בדוק בדוא"ל אם קיבלת הודעה.</Typography>
					}
				</div>
			</DialogContent>
		</>
	);
};

export default ForgotPasswordContent;
